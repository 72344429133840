// src/app/page.tsx

'use client';

import React, { useEffect, ReactNode, useCallback } from 'react';
import { signIn, useSession } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import useSWR from 'swr';
import { trackSignUp } from '@/utils/analytics';
import { safeStringify } from '@/utils/api';

const SampleProcessedDocumentResultsViewer = dynamic(() => import('@/components/SampleProcessedDocumentResultsViewer'), { ssr: false });
const Features = dynamic(() => import('@/components/Features'), { ssr: false });
const TechCapabilities = dynamic(() => import('@/components/TechCapabilities'), { ssr: false });
const PDFViewer = dynamic(() => import('@/components/PDFViewer'), { ssr: false });
const HighlightReel = dynamic(() => import('@/components/HighlightReel'), { ssr: false });

interface FeatureItemProps {
  title: string;
  content: React.ReactNode;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ title, content }) => (
  <div className="flex flex-col">
    <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">{title}</dt>
    <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">{content}</dd>
  </div>
);

interface TestimonialCardProps {
  content: string;
  author: string;
  role: string;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({ content, author, role }) => (
  <div className="bg-white p-6 rounded-xl shadow-lg">
    <p className="text-gray-600 mb-4">&ldquo;{content}&rdquo;</p>
    <div className="font-semibold">{author}</div>
    <div className="text-sm text-gray-500">{role}</div>
  </div>
);

type SampleFileKey = keyof typeof sampleFiles;

const sampleFiles = {
  markdown: {
    path: '/sample_files/Combined_Document_Optimizer_Output_File_Bundle__Document__buffett_1261db1d/optimized_document.md',
    displayName: 'Optimized Formatted Document (Markdown)',
  },
  gradedMultipleChoiceQuizHtml: {
    path: '/sample_files/Combined_Document_Optimizer_Output_File_Bundle__Document__buffett_1261db1d/graded_multiple_choice_quiz.html',
    displayName: 'Graded Multiple Choice Quiz (as HTML)',
  },
  presentationSlidesPdf: {
    path: '/sample_files/Combined_Document_Optimizer_Output_File_Bundle__Document__buffett_1261db1d/presentation_slides.pdf',
    displayName: 'Presentation Slides PDF',
  },
  presentationSlidesHtml: {
    path: '/sample_files/Combined_Document_Optimizer_Output_File_Bundle__Document__buffett_1261db1d/presentation_slides.html',
    displayName: 'Presentation Slides HTML',
  },
  readabilityAnalysis: {
    path: '/sample_files/Combined_Document_Optimizer_Output_File_Bundle__Document__buffett_1261db1d/readability_heatmap.html',
    displayName: 'Readability Analysis (HTML)',
  },
  mindmapOutline: {
    path: '/sample_files/Combined_Document_Optimizer_Output_File_Bundle__Document__buffett_1261db1d/mindmap_outline.html',
    displayName: 'Mindmap Outline (HTML)',
  },
  mindmapDiagram: {
    path: '/sample_files/Combined_Document_Optimizer_Output_File_Bundle__Document__buffett_1261db1d/mindmap.html',
    displayName: 'Mindmap Diagram (HTML)',
  },
  lessonPlan: {
    path: '/sample_files/Combined_Document_Optimizer_Output_File_Bundle__Document__buffett_1261db1d/lesson_plan.md',
    displayName: 'Lesson Plan (Markdown)',
  },
  gradeLevelVersion: {
    path: '/sample_files/Combined_Document_Optimizer_Output_File_Bundle__Document__buffett_1261db1d/grade_level_version_elementary.md',
    displayName: 'Grade Level Version - Elementary (Markdown)',
  },
  flashCards: {
    path: '/sample_files/Combined_Document_Optimizer_Output_File_Bundle__Document__buffett_1261db1d/flash_cards.csv',
    displayName: 'Anki Flash Cards (CSV)',
  },
  flashCardsApkg: {
    path: '/sample_files/Combined_Document_Optimizer_Output_File_Bundle__Document__buffett_1261db1d/anki_flashcards.apkg',
    displayName: 'Anki Flash Cards (Ready for Import)',
  },
};

// ErrorBoundary Component
interface ErrorBoundaryProps {
  children: ReactNode;
}
interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }
  static getDerivedStateFromError(error: Error): Partial<ErrorBoundaryState> {
    return { hasError: true, error };
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('ErrorBoundary caught an error:', safeStringify(error), safeStringify(errorInfo));
    this.setState({ error, errorInfo });
  }
  render() {
    if (this.state.hasError && this.state.error) {
      return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-4">
          <h1 className="text-3xl font-bold text-red-600 mb-4">Something went wrong.</h1>
          <p className="text-lg text-gray-700 mb-2">An unexpected error has occurred.</p>
          <details className="whitespace-pre-wrap bg-white p-4 rounded shadow-inner">
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo?.componentStack}
          </details>
          <button
            onClick={() => {
              window.location.reload();
            }}
            className="mt-4 px-4 py-2 bg-teal-600 text-white rounded hover:bg-teal-700"
          >
            Reload Page
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

// A fetcher for SWR
const fetcher = async (url: string) => {
  const res = await fetch(url);
  if (!res.ok) {
    const data = await res.json().catch(() => ({}));
    throw new Error(data.error || 'Failed to fetch');
  }
  return res.json();
};

// Another fetcher for text files
const textFetcher = async (url: string) => {
  const res = await fetch(url);
  if (!res.ok) {
    throw new Error('Failed to fetch file: ' + url);
  }
  return res.text();
};

const binaryFetcher = async (url: string) => {
  const res = await fetch(url);
  if (!res.ok) {
    throw new Error('Failed to fetch file: ' + url);
  }
  const blob = await res.blob();
  return blob;
};

export default function Landing() {
  const { data: session, status } = useSession();
  const router = useRouter();

  // We'll use SWR to check free credits if authenticated
  // Only call once authenticated
  const shouldCheckFreeCredits = status === 'authenticated' && session?.user?.email;
  const { data: freeCreditsData, error: freeCreditsError } = useSWR(shouldCheckFreeCredits ? '/api/check-free-credits' : null, fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
  });

  // Use SWR for sample files loading
  // We'll create a single SWR hook that fetches all files concurrently
  const { data: fileContentsData, error: fileContentsError } = useSWR(
    'sample-files',
    async () => {
      const entries = await Promise.all(
        Object.entries(sampleFiles).map(async ([key, value]) => {
          // Handle binary files differently
          if (value.path.endsWith('.apkg')) {
            const blob = await binaryFetcher(value.path);
            // Convert blob to array buffer
            const arrayBuffer = await blob.arrayBuffer();
            // Create a Uint8Array from the array buffer
            const uint8Array = new Uint8Array(arrayBuffer);
            return [key, uint8Array];
          } else {
            // For text files, use the existing text fetcher
            const text = await textFetcher(value.path);
            return [key, text];
          }
        })
      );
      return Object.fromEntries(entries);
    },
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnReconnect: false,
    }
  );

  // Check free credits on mount and redirect if needed
  useEffect(() => {
    if (status === 'authenticated' && session?.user?.email) {
      try {
        if (freeCreditsData || freeCreditsError) {
          trackSignUp(session.user.email);
          router.push('/dashboard');
        }
      } catch (error) {
        console.error('Error in authentication flow:', safeStringify(error));
        router.push('/dashboard');
      }
    }
  }, [status, session, router, freeCreditsData, freeCreditsError]);

  const handleDownloadZip = useCallback(() => {
    const link = document.createElement('a');
    link.href = '/sample_files/Combined_Document_Optimizer_Output_File_Bundle__Document__buffett_1261db1d.zip';
    link.download = 'Sample_Document_Processing_Output.zip';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  const fileContents = fileContentsData || {};

  return (
    <ErrorBoundary>
      <div className="flex flex-col min-h-screen">
        {/* Hero Section */}
        <section className="relative overflow-hidden bg-gradient-to-br from-teal-500 via-blue-500 to-green-500 text-white">
          <div className="max-w-7xl mx-auto px-6 py-24 sm:py-32 lg:px-8">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
                <motion.h1
                  className="text-4xl font-bold tracking-tight text-white sm:text-6xl"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  Process Any Document or Audio File with Advanced AI
                </motion.h1>
                <motion.p
                  className="mt-6 text-lg leading-8 text-teal-100"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                >
                  From PDFs and Office documents to audio recordings and scanned files, our AI handles it all. Upload your content and get perfectly
                  formatted documents, transcriptions, and study materials in minutes. Features advanced OCR correction and automatic formatting
                  optimization.
                </motion.p>
                <motion.div
                  className="mt-8 sm:flex sm:justify-center lg:justify-start"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                >
                  <div className="rounded-md shadow">
                    <button
                      onClick={() => signIn('google')}
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-teal-700 bg-white hover:bg-teal-50 md:py-4 md:text-lg md:px-10"
                      data-gtm-click="get-started-landing"
                    >
                      Get started
                    </button>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <Link
                      href="/pricing"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 md:py-4 md:text-lg md:px-10"
                    >
                      View Pricing
                    </Link>
                  </div>
                </motion.div>
              </div>
              <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
                  <motion.div
                    className="relative block w-full bg-white rounded-lg overflow-hidden"
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                  >
                    <HighlightReel />
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section id="features" className="py-10 bg-white">
          <Features />
        </section>

        {/* Technical Capabilities Section */}
        <section className="mt-4 sm:mt-6">
          <TechCapabilities />
        </section>

        {/* Sample Output Section */}
        <section className="py-20 bg-gray-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-2xl sm:text-3xl font-extrabold text-gray-900 text-center mb-12">See it in Action</h2>
            <div className="mb-12">
              <h3 className="text-xl sm:text-2xl font-bold mb-4">Sample Input Document</h3>
              <div className="bg-white p-4 rounded-lg shadow">
                <p className="text-gray-600 mb-6">
                  To demonstrate our system&apos;s capabilities with challenging documents, we processed a typewritten, scanned memorandum by Warren
                  Buffett discussing pension accounting practices. This document showcases our advanced OCR capabilities in handling complex financial
                  content, technical terminology, and vintage typewritten formatting.
                </p>
                <div className="border rounded-lg overflow-hidden">
                  <PDFViewer url="/sample_files/buffett.pdf" />
                </div>
              </div>
            </div>
            <div className="mb-8">
              <h3 className="text-xl sm:text-2xl font-bold mb-4">AI-Generated Outputs — Automatically Created in Minutes:</h3>
              <SampleProcessedDocumentResultsViewer sampleFiles={sampleFiles} fileContents={fileContents} onDownloadZip={handleDownloadZip} />
            </div>
          </div>
        </section>

        {/* Testimonials Section */}
        <section id="testimonials" className="py-20 bg-gradient-to-r from-green-500 to-teal-600">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-extrabold text-white text-center mb-12">What our users are saying</h2>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
              <TestimonialCard
                content="This tool has completely transformed how I prepare materials for my classes. It saves me hours of work!"
                author="Dr. Emily Johnson"
                role="University Professor"
              />
              <TestimonialCard
                content="The AI-powered summaries and quizzes are incredibly accurate and helpful for our team's documentation."
                author="Michael Chang"
                role="Technical Writer"
              />
              <TestimonialCard
                content="I love how easy it is to generate quizzes from my documents. My students' engagement has skyrocketed!"
                author="Sarah Martinez"
                role="High School Teacher"
              />
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section id="faq" className="py-20 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-12">Frequently Asked Questions</h2>
            <div className="mt-12 space-y-8">
              {[
                {
                  question: 'What types of documents can I process?',
                  answer:
                    'FixMyDocuments supports a wide range of document formats including PDF, DOCX, TXT, PPTX, images, and even audio/video files like MP3, WAV, and MP4.',
                },
                {
                  question: 'How accurate is the AI-powered text extraction and summarization?',
                  answer: 'Our AI text extraction and summarization boasts an accuracy rate of over 95% for clear, well-formatted documents.',
                },
                {
                  question: 'Can I edit the generated content?',
                  answer: 'Yes, you have full control to edit all AI-generated content to ensure perfect accuracy and customization for your needs.',
                },
                {
                  question: 'What file formats are supported for output?',
                  answer:
                    'We support various output formats including TXT, Markdown, PDF, HTML, and more. For quizzes and flashcards, we offer specialized formats like Anki decks.',
                },
                {
                  question: 'Is there a limit to document length or number of documents?',
                  answer: (
                    <>
                      Our plans offer various limits. Check our{' '}
                      <Link href="/pricing" className="text-blue-600 hover:underline">
                        pricing page
                      </Link>{' '}
                      for detailed information on document length and quantity limits.
                    </>
                  ),
                },
              ].map((faq, index) => (
                <div key={index}>
                  <h3 className="text-xl font-semibold text-gray-900">{faq.question}</h3>
                  <p className="mt-2 text-base text-gray-500">{typeof faq.answer === 'string' ? faq.answer : <>{faq.answer}</>}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section id="pricing" className="bg-gray-50">
          <div className="max-w-7xl mx-auto py-12 px-6 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">Ready to optimize your documents?</span>
              <span className="block text-teal-600">Start transforming your content today.</span>
            </h2>
            <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
              <div className="inline-flex rounded-md shadow">
                <button
                  onClick={() => signIn('google')}
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700"
                >
                  Get started
                </button>
              </div>
              <div className="ml-3 inline-flex rounded-md shadow">
                <Link
                  href="/pricing"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-teal-600 bg-white hover:bg-teal-50"
                >
                  Learn more
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* Footer Section */}
        <footer className="bg-gray-100 py-4">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center text-sm text-gray-500">
              <span>&copy; {new Date().getFullYear()} FixMyDocuments.com. All rights reserved. </span>
              <Link href="/blog" className="text-gray-600 hover:text-gray-900 ml-2">
                Blog
              </Link>
              <Link href="/terms-of-service" className="text-gray-600 hover:text-gray-900 ml-2">
                Terms of Service
              </Link>
              <span className="mx-2">·</span>
              <Link href="/privacy-policy" className="text-gray-600 hover:text-gray-900">
                Privacy Policy
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </ErrorBoundary>
  );
}
