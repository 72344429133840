// src/app/client-layout.tsx

'use client'

import { AntdRegistry } from '@ant-design/nextjs-registry'
import Providers from './providers'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import Script from 'next/script'
import '@fontsource/inter'
import { App as AntdApp, ConfigProvider } from 'antd'
import 'antd/dist/reset.css'

const paypalOptions = {
  clientId: process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID!,
  currency: "USD",
  intent: "capture",
}

export default function ClientLayout({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <>
      <Script
        src="https://app.tinyanalytics.io/pixel/lHETBHl957NYdLSr"
        strategy="afterInteractive"
      />
      <AntdRegistry>
        <ConfigProvider>
          <Providers>
            <PayPalScriptProvider options={paypalOptions}>
              <AntdApp>
                {children}
              </AntdApp>
            </PayPalScriptProvider>
          </Providers>
        </ConfigProvider>
      </AntdRegistry>
    </>
  )
}